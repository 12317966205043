import { types } from 'react-bricks/frontend'

type Images = Record<string, types.IImageSource>

export const customers = {
  WOOSMAP: {
    src: 'https://images.reactbricks.com/original/93ed8ddd-a8cd-40dc-a4dd-d954ea568cad.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/93ed8ddd-a8cd-40dc-a4dd-d954ea568cad.svg',
    srcSet: '',
    alt: 'Woosmap',
    seoName: 'woosmap',
    width: 997.334,
    height: 198.205,
  },
  CAPBASE: {
    src: 'https://images.reactbricks.com/original/6278b20a-e04d-4e0e-b2dd-d8e27228c069.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/6278b20a-e04d-4e0e-b2dd-d8e27228c069.svg',
    srcSet: '',
    alt: 'Capbase',
    seoName: 'capbase',
    width: 1000,
    height: 300,
  },
  CASAVO: {
    src: 'https://images.reactbricks.com/original/b6895334-198a-43d9-aa53-f27b7ff75f53.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/b6895334-198a-43d9-aa53-f27b7ff75f53.svg',
    srcSet: '',
    alt: 'Casavo',
    seoName: 'casavo',
    width: 520.76,
    height: 135.83,
  },
  EVERFUND: {
    src: 'https://images.reactbricks.com/original/9124b82c-686e-4de5-bd14-291d2fce37b8.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/9124b82c-686e-4de5-bd14-291d2fce37b8.svg',
    srcSet: '',
    alt: 'Everfund',
    seoName: 'everfund',
    width: 2698.39,
    height: 585.2,
  },
  NEOSKOP: {
    src: 'https://images.reactbricks.com/original/e39a61c5-0a25-49bd-9f77-3d29fb43e5af.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/e39a61c5-0a25-49bd-9f77-3d29fb43e5af.svg',
    srcSet: '',
    alt: 'Neoskop',
    seoName: 'neoskop',
    width: 145,
    height: 40,
  },
} as const satisfies Images

export const logos = {
  REACT: {
    src: 'https://images.reactbricks.com/original/5a717763-afd5-4ec5-8a68-12a0d6e4fd08.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/5a717763-afd5-4ec5-8a68-12a0d6e4fd08.svg',
    srcSet: '',
    alt: 'React',
    seoName: 'react',
    width: 120,
    height: 60,
  },
  VUE: {
    src: 'https://images.reactbricks.com/original/272fad93-049f-4cd7-bdd9-4ed823ba2599.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/272fad93-049f-4cd7-bdd9-4ed823ba2599.svg',
    srcSet: '',
    alt: 'Vue',
    seoName: 'vue',
    width: 120,
    height: 60,
  },
  SVELTE: {
    src: 'https://images.reactbricks.com/original/44c7c7db-06f9-4e33-b017-1b32a397b96b.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/44c7c7db-06f9-4e33-b017-1b32a397b96b.svg',
    srcSet: '',
    alt: 'Svelte',
    seoName: 'svelte',
    width: 800,
    height: 800,
  },
  SOLID: {
    src: 'https://images.reactbricks.com/original/99a92b01-c9a6-482b-8ed6-aefb20687754.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/99a92b01-c9a6-482b-8ed6-aefb20687754.svg',
    srcSet: '',
    alt: 'Solidjs',
    seoName: 'solidjs',
    width: 382.23,
    height: 70.7,
  },
  ASTRO: {
    src: 'https://images.reactbricks.com/original/faba4d56-5733-432c-a38d-25d701ea7dcf.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/faba4d56-5733-432c-a38d-25d701ea7dcf.svg',
    srcSet: '',
    alt: 'Astro',
    seoName: 'astro-build',
    width: 2712,
    height: 894,
  },
  REACT_BRICKS: {
    // src: 'https://images.reactbricks.com/original/7fd7ef1a-928f-45d6-b7a7-ff34bf91c15e.svg',
    // placeholderSrc:
    //   'https://images.reactbricks.com/original/7fd7ef1a-928f-45d6-b7a7-ff34bf91c15e.svg',
    src: 'https://images.reactbricks.com/original/193bc5b1-dcdd-4bca-8ae7-472780eead52.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/193bc5b1-dcdd-4bca-8ae7-472780eead52.svg',
    srcSet: '',
    alt: 'React Bricks',
    seoName: 'react-bricks',
    width: 1700.787,
    height: 377.953,
  },
} as const satisfies Images

export const iconLogos = {
  REACT: {
    src: 'https://images.reactbricks.com/original/6a840f50-48f3-45e4-9946-18586c039b2a.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/6a840f50-48f3-45e4-9946-18586c039b2a.svg',
    srcSet: '',
    alt: 'React',
    seoName: 'react',
    width: 23,
    height: 20.46348,
  },
  VUE: {
    src: 'https://images.reactbricks.com/original/ce61fb1d-bc1d-4619-91ad-f01e3981521f.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/ce61fb1d-bc1d-4619-91ad-f01e3981521f.svg',
    srcSet: '',
    alt: 'Vue',
    seoName: 'vue',
    width: 261.76,
    height: 226.69,
  },
  SVELTE: {
    src: 'https://images.reactbricks.com/original/d6598c2b-1093-4fbd-9a1d-5a89af2cde26.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/d6598c2b-1093-4fbd-9a1d-5a89af2cde26.svg',
    srcSet: '',
    alt: 'Svelte',
    seoName: 'svelte',
    width: 98.1,
    height: 118,
  },
  SOLID: {
    src: 'https://images.reactbricks.com/original/2f0e0ce5-a679-42de-9979-3e870540dd49.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/2f0e0ce5-a679-42de-9979-3e870540dd49.svg',
    srcSet: '',
    alt: 'Solidjs',
    seoName: 'solidjs',
    width: 166,
    height: 155.3,
  },
  ASTRO: {
    src: 'https://images.reactbricks.com/original/b51d2e41-02b9-4a5f-acab-68498c22b384.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/b51d2e41-02b9-4a5f-acab-68498c22b384.svg',
    srcSet: '',
    alt: 'Astro',
    seoName: 'astro-build',
    width: 1280,
    height: 1280,
  },
  REACT_BRICKS: {
    src: 'https://images.reactbricks.com/original/0502a7bd-0319-4300-b1df-89876e82c965.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/0502a7bd-0319-4300-b1df-89876e82c965.svg',
    srcSet: '',
    alt: 'React Bricks',
    seoName: 'react-bricks',
    width: 980,
    height: 979.97,
  },
} as const satisfies Images

export const avatars = {
  MATTEO_FRANA: {
    src: 'https://images.reactbricks.com/original/910d4267-6e46-4d9e-8790-53348ede99fb.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/910d4267-6e46-4d9e-8790-53348ede99fb.svg',
    srcSet: '',
    alt: 'Matteo Frana',
    seoName: 'matteo-frana',
  },
  STEFAN_NAGEY: {
    src: 'https://images.reactbricks.com/original/16068142-973d-49e7-b1a2-264c16bd5c34.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/16068142-973d-49e7-b1a2-264c16bd5c34.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/16068142-973d-49e7-b1a2-264c16bd5c34-100.webp 100w',
    alt: 'Stefan Nagey',
    seoName: 'stefan-nagey',
    fallbackSrc:
      'https://images.reactbricks.com/original/16068142-973d-49e7-b1a2-264c16bd5c34.png',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/16068142-973d-49e7-b1a2-264c16bd5c34-100.png 100w',
    fallbackType: 'image/jpeg',
    width: 100,
    height: 100,
  },
  LAURIE_VOSS: {
    src: 'https://images.reactbricks.com/original/1f56e3f7-e1cf-4bfa-82d4-6a1a7415b954.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/1f56e3f7-e1cf-4bfa-82d4-6a1a7415b954.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/1f56e3f7-e1cf-4bfa-82d4-6a1a7415b954-96.webp 96w',
    alt: 'Laurie Voss',
    seoName: 'laurie-voss',
    fallbackSrc:
      'https://images.reactbricks.com/original/1f56e3f7-e1cf-4bfa-82d4-6a1a7415b954.jpg',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/1f56e3f7-e1cf-4bfa-82d4-6a1a7415b954-96.jpg 96w',
    fallbackType: 'image/jpeg',
    width: 96,
    height: 96,
  },
  MAIK_JABLONSKI: {
    src: 'https://images.reactbricks.com/original/44e9d50a-95a1-4573-aafc-84a94496e319.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/44e9d50a-95a1-4573-aafc-84a94496e319.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/44e9d50a-95a1-4573-aafc-84a94496e319-450.webp 450w,\nhttps://images.reactbricks.com/src_set/44e9d50a-95a1-4573-aafc-84a94496e319-400.webp 400w,\nhttps://images.reactbricks.com/src_set/44e9d50a-95a1-4573-aafc-84a94496e319-200.webp 200w',
    alt: 'Maik Jablonski',
    seoName: 'maik-jablonski',
    fallbackSrc:
      'https://images.reactbricks.com/original/44e9d50a-95a1-4573-aafc-84a94496e319.jpg',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/44e9d50a-95a1-4573-aafc-84a94496e319-450.jpg 450w,\nhttps://images.reactbricks.com/src_set/44e9d50a-95a1-4573-aafc-84a94496e319-400.jpg 400w,\nhttps://images.reactbricks.com/src_set/44e9d50a-95a1-4573-aafc-84a94496e319-200.jpg 200w',
    fallbackType: 'image/jpeg',
    width: 450,
    height: 450,
  },
  PLACEHOLDER1: {
    src: 'https://images.reactbricks.com/original/cc2a047d-15f7-47d2-af8f-d4c3a394ca41.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/cc2a047d-15f7-47d2-af8f-d4c3a394ca41.svg',
    srcSet: '',
    alt: 'Place Holder',
    seoName: 'placeholder',
    width: 1249.24,
    height: 1249.24,
  },
  AVATAR_MALE: {
    src: 'https://images.reactbricks.com/original/2f84867e-3c4b-46b6-b64f-6c2f81b5232f.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/2f84867e-3c4b-46b6-b64f-6c2f81b5232f.svg',
    srcSet: '',
    alt: 'Alvin',
    seoName: 'alvin',
    width: 102.45,
    height: 102.45,
  },
  AVATAR_FEMALE: {
    src: 'https://images.reactbricks.com/original/24b26149-514a-40c4-9029-0dfaa22cbe3c.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/24b26149-514a-40c4-9029-0dfaa22cbe3c.svg',
    srcSet: '',
    alt: 'Catherine',
    seoName: 'catherine',
    width: 102.45,
    height: 102.45,
  },
} as const satisfies Images

export const icons = {
  PHOTOS: {
    src: 'https://images.reactbricks.com/original/21ee754a-8c3d-427a-bc44-8816c05299ae.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/21ee754a-8c3d-427a-bc44-8816c05299ae.svg',
    srcSet: '',
    alt: 'Great for content creators and developers',
    seoName: 'content-creators-ux-cms',
    width: 48,
    height: 48,
  },
  TWITTER: {
    src: 'https://images.reactbricks.com/original/3a2856d6-c209-4c90-9483-85d9959999e2.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/3a2856d6-c209-4c90-9483-85d9959999e2.svg',
    srcSet: '',
    width: 248,
    height: 204,
    alt: 'Twitter icon',
    seoName: 'twitter-icon',
  },
  YOUTUBE: {
    src: 'https://images.reactbricks.com/original/02fb7799-0da2-4537-a6c7-4822bc1410a2.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/02fb7799-0da2-4537-a6c7-4822bc1410a2.svg',
    srcSet: '',
    width: 159,
    height: 110,
    alt: 'Youtube icon',
    seoName: 'youtube-icon',
  },
  PHOTO_STACK: {
    src: 'https://images.reactbricks.com/original/aca3dbf3-ccb6-47cf-973e-059e85e55571.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/aca3dbf3-ccb6-47cf-973e-059e85e55571.svg',
    srcSet: '',
    width: 1,
    height: 1,
    alt: 'Best UX for editors',
    seoName: 'best-ux-editors',
  },
  DATABASE: {
    src: 'https://images.reactbricks.com/original/0037d5f4-d486-4cdf-a64c-dcbf0260ebb3.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/0037d5f4-d486-4cdf-a64c-dcbf0260ebb3.svg',
    srcSet: '',
    width: 1,
    height: 1,
    alt: 'Enterprise-ready',
    seoName: 'enterprise-ready',
  },
  MIND_MAP: {
    src: 'https://images.reactbricks.com/original/dd14c0fe-3f21-4fc1-8362-22e005e82897.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/dd14c0fe-3f21-4fc1-8362-22e005e82897.svg',
    srcSet: '',
    width: 1,
    height: 1,
    alt: 'React components',
    seoName: 'react-components',
  },
  RADAR_PLOT: {
    src: 'https://images.reactbricks.com/original/6f0a3910-b542-4791-a2ab-57474b9b2bb1.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/6f0a3910-b542-4791-a2ab-57474b9b2bb1.svg',
    srcSet: '',
    width: 1,
    height: 1,
    alt: 'Design system',
    seoName: 'design-system',
  },
  VISUAL_EDITING: {
    src: 'https://images.reactbricks.com/original/50313730-79c9-4d6a-b7e0-d8aeb2a936e2.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/50313730-79c9-4d6a-b7e0-d8aeb2a936e2.svg',
    srcSet: '',
    width: 48,
    height: 48,
    alt: 'Best UX for editors',
    seoName: 'best-ux-editors',
  },
  COMPONENTS: {
    src: 'https://images.reactbricks.com/original/cca08a7b-c3ad-4928-a69b-84b5d9e06ef4.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/cca08a7b-c3ad-4928-a69b-84b5d9e06ef4.svg',
    srcSet: '',
    width: 48,
    height: 48,
    alt: 'React components',
    seoName: 'react-components',
  },
  MULTILANGUAGE: {
    src: 'https://images.reactbricks.com/original/643f6d1e-2c4f-40bd-8478-82b43694054b.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/643f6d1e-2c4f-40bd-8478-82b43694054b.svg',
    srcSet: '',
    width: 48,
    height: 48,
    alt: '',
    seoName: '',
  },
  SCHEDULED_PUBLISHING: {
    src: 'https://images.reactbricks.com/original/3eaa0b6b-bcf0-4430-b099-3c4f872a6d91.svg',
    placeholderSrc:
      'https://images.reactbricks.com/original/3eaa0b6b-bcf0-4430-b099-3c4f872a6d91.svg',
    srcSet: '',
    width: 48,
    height: 48,
    alt: 'Enterprise-ready',
    seoName: 'enterprise-ready',
  },
} as const satisfies Images

export const photos = {
  DESK_MAC: {
    src: 'https://images.reactbricks.com/original/91a94bb2-7916-4254-9c60-af8d69701dfc.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/91a94bb2-7916-4254-9c60-af8d69701dfc.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/91a94bb2-7916-4254-9c60-af8d69701dfc-1080.webp 1080w,\nhttps://images.reactbricks.com/src_set/91a94bb2-7916-4254-9c60-af8d69701dfc-800.webp 800w,\nhttps://images.reactbricks.com/src_set/91a94bb2-7916-4254-9c60-af8d69701dfc-400.webp 400w,\nhttps://images.reactbricks.com/src_set/91a94bb2-7916-4254-9c60-af8d69701dfc-200.webp 200w',
    alt: 'person writing on white paper',
    seoName: 'dashboard',
    fallbackSrc:
      'https://images.reactbricks.com/original/91a94bb2-7916-4254-9c60-af8d69701dfc.jpg',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/91a94bb2-7916-4254-9c60-af8d69701dfc-1080.jpg 1080w,\nhttps://images.reactbricks.com/src_set/91a94bb2-7916-4254-9c60-af8d69701dfc-800.jpg 800w,\nhttps://images.reactbricks.com/src_set/91a94bb2-7916-4254-9c60-af8d69701dfc-400.jpg 400w,\nhttps://images.reactbricks.com/src_set/91a94bb2-7916-4254-9c60-af8d69701dfc-200.jpg 200w',
    fallbackType: 'image/jpeg',
    width: 1080,
    height: 717,
  },
  IMAGE_TEXT_STORY_HERO: {
    src: 'https://images.reactbricks.com/original/1a8b35b7-4793-4c72-836b-86a491718494.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/1a8b35b7-4793-4c72-836b-86a491718494.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/1a8b35b7-4793-4c72-836b-86a491718494-1080.webp 1080w,\nhttps://images.reactbricks.com/src_set/1a8b35b7-4793-4c72-836b-86a491718494-800.webp 800w,\nhttps://images.reactbricks.com/src_set/1a8b35b7-4793-4c72-836b-86a491718494-400.webp 400w,\nhttps://images.reactbricks.com/src_set/1a8b35b7-4793-4c72-836b-86a491718494-200.webp 200w',
    alt: 'macbook pro displaying computer icons',
    seoName: 'dashboard',
    fallbackSrc:
      'https://images.reactbricks.com/original/1a8b35b7-4793-4c72-836b-86a491718494macbook pro displaying computer icons',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/1a8b35b7-4793-4c72-836b-86a491718494-1080macbook pro displaying computer icons 1080w,\nhttps://images.reactbricks.com/src_set/1a8b35b7-4793-4c72-836b-86a491718494-800macbook pro displaying computer icons 800w,\nhttps://images.reactbricks.com/src_set/1a8b35b7-4793-4c72-836b-86a491718494-400macbook pro displaying computer icons 400w,\nhttps://images.reactbricks.com/src_set/1a8b35b7-4793-4c72-836b-86a491718494-200macbook pro displaying computer icons 200w',
    fallbackType: 'image/jpeg',
    width: 1080,
    height: 608,
  },
  SEASIDE: {
    fallbackSrc:
      'https://images.reactbricks.com/original/71fd29e5-d54d-4c99-a2da-681bd8d888d1.jpg',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/71fd29e5-d54d-4c99-a2da-681bd8d888d1-1080.jpg 1080w,\nhttps://images.reactbricks.com/src_set/71fd29e5-d54d-4c99-a2da-681bd8d888d1-600.jpg 600w,\nhttps://images.reactbricks.com/src_set/71fd29e5-d54d-4c99-a2da-681bd8d888d1-300.jpg 300w',
    fallbackType: 'image/jpeg',
    src: 'https://images.reactbricks.com/original/71fd29e5-d54d-4c99-a2da-681bd8d888d1.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/71fd29e5-d54d-4c99-a2da-681bd8d888d1.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/71fd29e5-d54d-4c99-a2da-681bd8d888d1-1080.webp 1080w,\nhttps://images.reactbricks.com/src_set/71fd29e5-d54d-4c99-a2da-681bd8d888d1-600.webp 600w,\nhttps://images.reactbricks.com/src_set/71fd29e5-d54d-4c99-a2da-681bd8d888d1-300.webp 300w',
    width: 1080,
    height: 606,
    alt: 'aerial photography of islands during daytime',
    seoName: 'seaside',
  },
  CAROUSEL_MOUNTAINS_1: {
    src: 'https://images.reactbricks.com/original/b83f614b-8dc9-4a20-b39f-a7e90374d4cc.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/b83f614b-8dc9-4a20-b39f-a7e90374d4cc.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/b83f614b-8dc9-4a20-b39f-a7e90374d4cc-1080.webp 1080w,\nhttps://images.reactbricks.com/src_set/b83f614b-8dc9-4a20-b39f-a7e90374d4cc-600.webp 600w,\nhttps://images.reactbricks.com/src_set/b83f614b-8dc9-4a20-b39f-a7e90374d4cc-300.webp 300w',
    width: 1080,
    height: 270,
    alt: 'aerial photography of mountain range covered with snow under white and blue sky at daytime',
    seoName: 'mountains',
    fallbackSrc:
      'https://images.reactbricks.com/original/b83f614b-8dc9-4a20-b39f-a7e90374d4cc.jpg',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/b83f614b-8dc9-4a20-b39f-a7e90374d4cc-1080.jpg 1080w,\nhttps://images.reactbricks.com/src_set/b83f614b-8dc9-4a20-b39f-a7e90374d4cc-600.jpg 600w,\nhttps://images.reactbricks.com/src_set/b83f614b-8dc9-4a20-b39f-a7e90374d4cc-300.jpg 300w',
    fallbackType: 'image/jpeg',
  },
  CAROUSEL_MOUNTAINS_2: {
    src: 'https://images.reactbricks.com/original/79c16949-6349-45de-996b-5a11b31800e6.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/79c16949-6349-45de-996b-5a11b31800e6.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/79c16949-6349-45de-996b-5a11b31800e6-1080.webp 1080w,\nhttps://images.reactbricks.com/src_set/79c16949-6349-45de-996b-5a11b31800e6-600.webp 600w,\nhttps://images.reactbricks.com/src_set/79c16949-6349-45de-996b-5a11b31800e6-300.webp 300w',
    width: 1080,
    height: 270,
    alt: 'snow mountain under stars',
    seoName: 'mountains',
    fallbackSrc:
      'https://images.reactbricks.com/original/79c16949-6349-45de-996b-5a11b31800e6.jpg',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/79c16949-6349-45de-996b-5a11b31800e6-1080.jpg 1080w,\nhttps://images.reactbricks.com/src_set/79c16949-6349-45de-996b-5a11b31800e6-600.jpg 600w,\nhttps://images.reactbricks.com/src_set/79c16949-6349-45de-996b-5a11b31800e6-300.jpg 300w',
    fallbackType: 'image/jpeg',
  },
  CAROUSEL_SEA_1: {
    src: 'https://images.reactbricks.com/original/ae2eea74-6e50-42cd-8dbe-9d17774a1643.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/ae2eea74-6e50-42cd-8dbe-9d17774a1643.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/ae2eea74-6e50-42cd-8dbe-9d17774a1643-720.webp 720w,\nhttps://images.reactbricks.com/src_set/ae2eea74-6e50-42cd-8dbe-9d17774a1643-600.webp 600w,\nhttps://images.reactbricks.com/src_set/ae2eea74-6e50-42cd-8dbe-9d17774a1643-300.webp 300w',
    width: 720,
    height: 720,
    alt: 'boat on seashore',
    seoName: 'seaside',
    fallbackSrc:
      'https://images.reactbricks.com/original/ae2eea74-6e50-42cd-8dbe-9d17774a1643.jpg',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/ae2eea74-6e50-42cd-8dbe-9d17774a1643-720.jpg 720w,\nhttps://images.reactbricks.com/src_set/ae2eea74-6e50-42cd-8dbe-9d17774a1643-600.jpg 600w,\nhttps://images.reactbricks.com/src_set/ae2eea74-6e50-42cd-8dbe-9d17774a1643-300.jpg 300w',
    fallbackType: 'image/jpeg',
  },
  CAROUSEL_SEA_2: {
    src: 'https://images.reactbricks.com/original/807fd735-89a6-4f30-b2fe-6eaba36e3319.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/807fd735-89a6-4f30-b2fe-6eaba36e3319.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/807fd735-89a6-4f30-b2fe-6eaba36e3319-1080.webp 1080w,\nhttps://images.reactbricks.com/src_set/807fd735-89a6-4f30-b2fe-6eaba36e3319-600.webp 600w,\nhttps://images.reactbricks.com/src_set/807fd735-89a6-4f30-b2fe-6eaba36e3319-300.webp 300w',
    width: 1080,
    height: 1080,
    alt: 'empty seashore',
    seoName: 'sea',
    fallbackSrc:
      'https://images.reactbricks.com/original/807fd735-89a6-4f30-b2fe-6eaba36e3319.jpg',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/807fd735-89a6-4f30-b2fe-6eaba36e3319-1080.jpg 1080w,\nhttps://images.reactbricks.com/src_set/807fd735-89a6-4f30-b2fe-6eaba36e3319-600.jpg 600w,\nhttps://images.reactbricks.com/src_set/807fd735-89a6-4f30-b2fe-6eaba36e3319-300.jpg 300w',
    fallbackType: 'image/jpeg',
  },
  CAROUSEL_SEA_3: {
    src: 'https://images.reactbricks.com/original/a30ad110-b981-4aea-bdd4-8bd27319d319.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/a30ad110-b981-4aea-bdd4-8bd27319d319.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/a30ad110-b981-4aea-bdd4-8bd27319d319-1080.webp 1080w,\nhttps://images.reactbricks.com/src_set/a30ad110-b981-4aea-bdd4-8bd27319d319-600.webp 600w,\nhttps://images.reactbricks.com/src_set/a30ad110-b981-4aea-bdd4-8bd27319d319-300.webp 300w',
    width: 1080,
    height: 1080,
    alt: 'aerial photography of large body of water and shoreline',
    seoName: 'sea',
    fallbackSrc:
      'https://images.reactbricks.com/original/a30ad110-b981-4aea-bdd4-8bd27319d319.jpg',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/a30ad110-b981-4aea-bdd4-8bd27319d319-1080.jpg 1080w,\nhttps://images.reactbricks.com/src_set/a30ad110-b981-4aea-bdd4-8bd27319d319-600.jpg 600w,\nhttps://images.reactbricks.com/src_set/a30ad110-b981-4aea-bdd4-8bd27319d319-300.jpg 300w',
    fallbackType: 'image/jpeg',
  },
  CAROUSEL_SEA_4: {
    src: 'https://images.reactbricks.com/original/b87f99ed-7b67-467c-a747-7327546a0fee.webp',
    placeholderSrc:
      'https://images.reactbricks.com/placeholder/b87f99ed-7b67-467c-a747-7327546a0fee.jpg',
    srcSet:
      'https://images.reactbricks.com/src_set/b87f99ed-7b67-467c-a747-7327546a0fee-1080.webp 1080w,\nhttps://images.reactbricks.com/src_set/b87f99ed-7b67-467c-a747-7327546a0fee-600.webp 600w,\nhttps://images.reactbricks.com/src_set/b87f99ed-7b67-467c-a747-7327546a0fee-300.webp 300w',
    width: 1080,
    height: 1080,
    alt: 'crystal clear water near coconut trees under the sun',
    seoName: 'sea',
    fallbackSrc:
      'https://images.reactbricks.com/original/b87f99ed-7b67-467c-a747-7327546a0fee.jpg',
    fallbackSrcSet:
      'https://images.reactbricks.com/src_set/b87f99ed-7b67-467c-a747-7327546a0fee-1080.jpg 1080w,\nhttps://images.reactbricks.com/src_set/b87f99ed-7b67-467c-a747-7327546a0fee-600.jpg 600w,\nhttps://images.reactbricks.com/src_set/b87f99ed-7b67-467c-a747-7327546a0fee-300.jpg 300w',
    fallbackType: 'image/jpeg',
  },
} as const satisfies Images
